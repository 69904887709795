import Head from 'next/head';
import Router from 'next/router';
/*
 * Copyright (C) 2019 Positive Zero SA de CV - Todos Los Derechos Reservados
 * La copia o distribución no autorizada de este archivo por cualquier medio está estrictamente prohibida
 *
 * Copyright (C) 2019 Positive Zero SA de CV - All Rights Reserved
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited
 */
import NProgress from 'nprogress';
import { FunctionComponent, ReactNode, useContext, useEffect } from 'react';
import UserContext from '../context/UserContext';
import IssueButton from './IssueButton';
import MainContent from './MainContent';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

type LayoutProps = {
  children: ReactNode;
  title: string;
  currentSection?: string;
  mainPadding?: string;
  navbarBackgroundColor?: string;
};

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  title,
  currentSection,
  mainPadding,
  navbarBackgroundColor
}: LayoutProps) => {
  const isBrowser = typeof window !== 'undefined';
  const user = useContext(UserContext);
  const isProduction = process.env.NODE_ENV === 'production';

  useEffect(() => {
    if (isBrowser && isProduction) {
      const OneSignalLib = (window as any).OneSignal || [];
      OneSignalLib.push(function () {
        // Occurs when the user's subscription changes to a new value.
        OneSignalLib.on('subscriptionChange', function (isSubscribed: boolean) {
          if (isSubscribed) {
            OneSignalLib.setExternalUserId(user?.id);
          }
        });
      });
      OneSignalLib.push(function () {
        OneSignalLib.on('notificationPermissionChange', function (permissionChange: { to: string }) {
          const currentPermission = permissionChange.to;
          if (currentPermission === 'granted') {
            OneSignalLib.setExternalUserId(user?.id);
          }
        });
      });
    }
  }, [isBrowser, isProduction]);

  return (
    <>
      <Head>
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta httpEquiv='X-UA-Compatible' content='ie=edge' />
        <title>{`${title} | Ayzer Dental`}</title>
        <meta
          name='description'
          content={`${title} | Ayzer Dental. Lleva la atención de tus pacientes a otro nivel.`}
        />

        <link rel='apple-touch-icon' sizes='57x57' href='/favicon/apple-icon-57x57.png' />
        <link rel='apple-touch-icon' sizes='60x60' href='/favicon/apple-icon-60x60.png' />
        <link rel='apple-touch-icon' sizes='72x72' href='/favicon/apple-icon-72x72.png' />
        <link rel='apple-touch-icon' sizes='76x76' href='/favicon/apple-icon-76x76.png' />
        <link rel='apple-touch-icon' sizes='114x114' href='/favicon/apple-icon-114x114.png' />
        <link rel='apple-touch-icon' sizes='120x120' href='/favicon/apple-icon-120x120.png' />
        <link rel='apple-touch-icon' sizes='144x144' href='/favicon/apple-icon-144x144.png' />
        <link rel='apple-touch-icon' sizes='152x152' href='/favicon/apple-icon-152x152.png' />
        <link rel='apple-touch-icon' sizes='180x180' href='/favicon/apple-icon-180x180.png' />
        <link rel='icon' type='image/png' sizes='192x192' href='/favicon/android-icon-192x192.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='96x96' href='/favicon/favicon-96x96.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon/favicon-16x16.png' />
        <link rel='manifest' href='/manifest.json' />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta name='msapplication-TileImage' content='/favicon/ms-icon-144x144.png' />
        <meta name='theme-color' content='#ffffff' />
        {isBrowser && isProduction && <script src='https://cdn.onesignal.com/sdks/OneSignalSDK.js' async></script>}
        {isBrowser && isProduction && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.OneSignal = window.OneSignal || [];
            OneSignal.push(function() {
              OneSignal.init({
                appId: '${process.env.ONESIGNAL_APP_ID}',
                safari_web_id: "web.onesignal.auto.57017041-c410-4b69-86f6-455278402f0c",
                notifyButton: {
                  enable: false
                },
                welcomeNotification: {
                  title: 'Gracias',
                  message: 'Así se verán las notificaciones'
                }
              });
              OneSignal.showNativePrompt();
            });
        `
            }}
          ></script>
        )}
      </Head>

      <MainContent
        currentSection={currentSection}
        mainPadding={mainPadding}
        navbarBackgroundColor={navbarBackgroundColor}
      >
        {process.env.SHOW_ISSUE_BUTTON && <IssueButton />}
        {children}
      </MainContent>
      {process.env.NODE_ENV === 'development' && (
        <div className='w-full fixed bottom-0 text-center bg-strongPrimary text-white p-2'>Development</div>
      )}
    </>
  );
};
export default Layout;
